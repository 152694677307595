<script>
  import courseFiltersMixin from '@/mixins/courseFiltersMixin.js';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import CourseGridCard from '@/components/courses/course-grid-card/CourseGridCard.vue';
  import CourseFiltersDialog from '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.vue';

  export default {
    mixins: [courseFiltersMixin],
    name: 'favourites',
    data() {
      return {
        isLoadingCourses: false,
        favoriteCourses: [],
      };
    },
    components: {
      CurvedHeader,
      CourseGridCard,
      CourseFiltersDialog,
    },
    watch: {
      async courses() {
        await this.fetchCourses();
        this.setFavoriteCourses(this.courses);
        this.setFilteredCourses(this.favoriteCourses);
      },
    },
    computed: {
      ...mapGetters('CoursesModule', ['courses']),
    },
    async created() {
      await this.fetchCourses();
      this.setFavoriteCourses(this.courses);
      this.setFilteredCourses(this.favoriteCourses);
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CoursesModule', { fetchAllCourses: 'fetchAll' }),

      // I couldn't just use the "to" attribute, because it overrides my styling
      goBackToProfile() {
        this.$router.push({
          name: 'Profile',
        });
      },
      async fetchCourses() {
        this.isLoadingCourses = true;
        try {
          await this.fetchAllCourses();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoadingCourses = false;
        }
      },
      setFavoriteCourses(courses) {
        this.favoriteCourses = courses.filter(course => course.isFavorite);
      },
    },
  };
</script>
