<template>
  <div class="icon-wrap">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="icon == 'heart'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.613 2.053a3.6 3.6 0 00-5.1 0L8 2.573l-.513-.52a3.6 3.6 0 00-5.1 0c-1.414 1.414-1.5 3.8.28 5.614L8 13l5.333-5.333c1.78-1.814 1.694-4.2.28-5.614z" stroke="var(--v-primaryColor-base)" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'history'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.5 3v5h5" stroke="var(--v-primaryColor-base)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.55 13A9 9 0 106.5 5.3L3.5 8" stroke="var(--v-primaryColor-base)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 7v5l4 2" stroke="var(--v-primaryColor-base)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'learn'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.579 31.59a8.705 8.705 0 01-2.316-5.922c0-4.838 3.94-8.76 8.8-8.76 4.86 0 8.8 3.922 8.8 8.76 0 1.849-.358 3.375-1.558 4.977-1.199 1.603-2.61 2.902-2.61 8.099h-10.19M28.695 44h-10.19m5.221-40v5.406m16.8 16.304H46M35.81 13.888l3.871-3.853m-27.368 3.853l-3.87-3.853M2 25.71h5.474" stroke="url(#paint0_linear_1759_2132)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><defs><linearGradient id="paint0_linear_1759_2132" x1="2" y1="24" x2="46" y2="24" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient></defs></svg>
    <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'meditate'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.034 36.149V23.146c0-2.169 1.718-3.927 3.837-3.927h9.184c2.119 0 3.837 1.758 3.837 3.927v13.003m-16.858 0l-3.907-2.952c-3.351-2.533-8.12-.335-8.127 3.928 0 .51.005 1.004.02 1.47.022.697.068 1.5.123 2.298A4.394 4.394 0 006.52 45h12.074m-4.56-8.851l8.407 6.082m8.45-6.082l-8.45 6.082m8.45-6.082l3.888-2.954c3.339-2.537 8.111-.368 8.207 3.887.009.412.014.811.014 1.191 0 .857-.067 1.872-.157 2.849C42.636 43.362 40.75 45 38.55 45H26.268m-3.827-2.769L18.594 45m3.847-2.769L26.268 45m-7.674 0h7.674m-3.837-19.408v4.249m6.605-21.082c0 3.733-2.957 6.759-6.605 6.759-3.647 0-6.604-3.026-6.604-6.759C15.827 5.026 18.784 2 22.43 2c3.648 0 6.605 3.026 6.605 6.759z" stroke="var(--v-secondaryColor-base)" stroke-width="2.5" stroke-linecap="round"/></svg>
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'sleep'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_1759_2148)"><mask id="a" fill="#fff"><path fill-rule="evenodd" clip-rule="evenodd" d="M43 26.5c0-.903-1.192-1.205-1.792-.531A14.963 14.963 0 0130 30.999c-8.284 0-15-6.715-15-15 0-3.28 1.053-6.315 2.84-8.784.555-.767-.016-1.924-.94-1.722C7.236 7.599 0 16.204 0 26.5 0 38.374 9.626 48 21.5 48S43 38.374 43 26.5z"/></mask><path d="M17.84 7.215l-2.025-1.466 2.025 1.466zm21.5 17.092A12.463 12.463 0 0130 28.5v5c5.2 0 9.874-2.272 13.075-5.869l-3.734-3.324zM30 28.5c-6.904 0-12.5-5.597-12.5-12.5h-5c0 9.665 7.835 17.5 17.5 17.5v-5zM17.5 16c0-2.738.877-5.263 2.366-7.32l-4.05-2.931a17.432 17.432 0 00-3.316 10.25h5zm-15 10.5c0-9.095 6.393-16.703 14.932-18.564L16.367 3.05C5.579 5.402-2.5 15.004-2.5 26.5h5zm19 19c-10.493 0-19-8.507-19-19h-5c0 13.254 10.745 24 24 24v-5zm19-19c0 10.493-8.507 19-19 19v5c13.255 0 24-10.746 24-24h-5zM19.866 8.68a3.668 3.668 0 00.272-3.852c-.615-1.17-2.018-2.16-3.771-1.778l1.065 4.886c-.34.074-.732.03-1.082-.155a1.51 1.51 0 01-.639-.627 1.332 1.332 0 01.104-1.405l4.05 2.931zm23.21 18.951a1.395 1.395 0 01-1.42.42 1.554 1.554 0 01-.769-.505A1.65 1.65 0 0140.5 26.5h5c0-1.767-1.243-2.914-2.499-3.265a3.605 3.605 0 00-3.66 1.072l3.734 3.324z" fill="var(--v-secondaryColor-base)" mask="url(#a)"/><path d="M27 1.5h7L27 12h7M40 11h6l-6 8h6" stroke="var(--v-secondaryColor-base)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1759_2148"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>
  </div>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon-wrap {
    width: 96px;
    height: 96px;
    border-radius: 16px;
    background-color: var(--v-offWhite-base);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 48px;
      height: 48px;
    }
  }
</style>
